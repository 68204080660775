import Deso from "deso-protocol";
const deso = new Deso({
  nodeUri: "https://diamondapp.com/api/v0",
});

const GetSinglePost = async (PostHashHex: string) => {
  const response = await deso.posts.getSinglePost({
    PostHashHex,
  });

  if (response.PostFound) return response.PostFound;
  return null;
};

const DesoService = {
  GetSinglePost,
};
export default DesoService;
