import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DesoService from "../services/Deso";
import { useEffect, useState } from "react";
import { PostEntryResponse } from "../constants/Types";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Helper from "../utils/Helper";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const NftPage = () => {
  const [post, SetNft] = useState<PostEntryResponse | null>(null);
  useEffect(() => {
    const PostHashHex =
      "ab6476cd679654fdc8dc1d767bed84c1549044045688b7534d8acb6ab3a82483";
    DesoService.GetSinglePost(PostHashHex).then((postFound) => {
      SetNft(postFound);
    });
  }, []);

  return (
    <>
      <h2 className="text-center mb-4">Buy NFT Tokens</h2>
      <hr />
      {post ? (
        <Row>
          <Col md="6" className="d-flex justify-content-center">
            {post?.ImageURLs ? (
              <Image thumbnail={true} src={post?.ImageURLs[0]} width="80%" />
            ) : (
              ""
            )}
          </Col>
          <Col md="6">
            <Card>
              <Card.Header as="h5" className="text-center">
                NFT Details
              </Card.Header>
              <Card.Body>
                <Table borderless hover>
                  <tbody>
                    <tr>
                      <th>Minted By</th>
                      <td>{post?.ProfileEntryResponse?.Username}</td>
                    </tr>
                    <tr>
                      <th>Minted On</th>
                      <td>
                        {Helper.convertTstampToDateTime(post?.TimestampNanos)}
                      </td>
                    </tr>
                    <tr>
                      <th>Copies</th>
                      <td>{post.NumNFTCopies}</td>
                    </tr>
                    <tr>
                      <th>Post</th>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: Helper.SanitizePost(post.Body),
                        }}
                      ></td>
                    </tr>
                  </tbody>
                </Table>

                <ButtonGroup className="w-100">
                  <Button variant="white">
                    {Helper.abbreviateNumber(post.LikeCount)} Likes
                  </Button>
                  <Button variant="white">
                    {Helper.abbreviateNumber(post.DiamondCount)} Diamonds
                  </Button>
                  <Button variant="white">
                    {Helper.abbreviateNumber(post.CommentCount)} Comments
                  </Button>
                  <Button variant="white">
                    {Helper.abbreviateNumber(post.RepostCount)} Reposts
                  </Button>
                </ButtonGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};
export default NftPage;
