import * as _ from "lodash";

const convertTstampToDaysOrHours = (tstampNanos: number) => {
  // get total seconds between the times
  let delta = Math.abs(tstampNanos / 1000000 - new Date().getTime()) / 1000;

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.ceil(delta / 60) % 60;

  return `${days ? days + "d " : ""} ${!days && hours ? hours + "h" : ""} ${
    !days && !hours && minutes ? minutes + "m" : ""
  }`;
};

const convertTstampToDays = (tstampNanos: number) => {
  // get total seconds between the times
  let delta = Math.abs(tstampNanos * 1000 - new Date().getTime()) / 1000;

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  return days;
};

const convertTstampToDateOrTime = (tstampNanos: number) => {
  const date = new Date(tstampNanos / 1e6);
  const currentDate = new Date();
  if (
    date.getDate() != currentDate.getDate() ||
    date.getMonth() != currentDate.getMonth() ||
    date.getFullYear() != currentDate.getFullYear()
  ) {
    return date.toLocaleString("default", { month: "short", day: "numeric" });
  }

  return date.toLocaleString("default", { hour: "numeric", minute: "numeric" });
};

const convertTstampToDateTime = (tstampNanos: number) => {
  const date = new Date(tstampNanos / 1e6);
  const currentDate = new Date();
  if (
    date.getDate() != currentDate.getDate() ||
    date.getMonth() != currentDate.getMonth() ||
    date.getFullYear() != currentDate.getFullYear()
  ) {
    return date.toLocaleString("default", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  }

  return date.toLocaleString("default", { hour: "numeric", minute: "numeric" });
};

const SanitizePost = (post: string): string => {
  // Escape to remove any HTML tags that may have been added by users
  post = _.escape(post);

  // limit of two newlines in a row
  post = _.replace(post, /\n\n+/g, "\n\n");

  // display newlines
  post = _.replace(post, /\n/g, "<br>");

  return post;
};

const abbreviateNumber = (
  value: number,
  decimals: number | null = null,
  _formatUSD: boolean = false
): string => {
  decimals = decimals ? decimals : value < 1000 ? 0 : 2;
  let shortValue;
  const suffixes = ["", "K", "M", "B", "T"];
  const suffixNum = Math.floor((("" + value.toFixed(0)).length - 1) / 3);
  if (suffixNum === 0) {
    // if the number is less than 1000, we should only show at most 2 decimals places
    decimals = Math.min(2, decimals);
  }
  shortValue = (value / Math.pow(1000, suffixNum)).toFixed(decimals);
  if (_formatUSD) {
    shortValue = formatUSD(Number(shortValue), decimals);
  }
  return shortValue + suffixes[suffixNum];
};

let formatUSDMemo: any = {};
let nanosToDeSoMemo: any = {};

const formatUSD = (num: number, decimal: number): string => {
  if (formatUSDMemo[num] && formatUSDMemo[num][decimal]) {
    return formatUSDMemo[num][decimal];
  }

  formatUSDMemo[num] = formatUSDMemo[num] || {};

  formatUSDMemo[num][decimal] = Number(num).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
  return formatUSDMemo[num][decimal];
};

const Helper = {
  convertTstampToDaysOrHours,
  convertTstampToDays,
  convertTstampToDateOrTime,
  convertTstampToDateTime,
  SanitizePost,
  abbreviateNumber,
  formatUSD,
};
export default Helper;
