import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RouteNames } from "../constants/RouteNames";
import SiteLayout from "../layouts/SiteLayout";
import Home from "../pages/Home";
import NftPage from "../pages/NftPage";

const Router = () => {
  return (
    <BrowserRouter>
      <SiteLayout>
        <Routes>
          <Route path={RouteNames.HOME} element={<NftPage />} />
          <Route path={RouteNames.ABOUT} element={<Home />} />
        </Routes>
      </SiteLayout>
    </BrowserRouter>
  );
};
export default Router;
