import Footer from "../components/layouts/Footer";
import Header from "../components/layouts/Header";
import Container from "react-bootstrap/Container";

const SiteLayout = (props: any) => {
  return (
    <>
      <Header />
      <Container className="mt-3">
        <main>{props.children}</main>
      </Container>
      <Footer />
    </>
  );
};

export default SiteLayout;
